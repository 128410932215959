import React, { useState } from "react";
import axios from "axios";
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useToast,
  Input,
  Button,
  Textarea,
  Box,
  Text,
  Flex,
  VStack,
  Divider,
  useMediaQuery,
  Avatar,
} from "@chakra-ui/react";
import { EditIcon, CheckIcon, CloseIcon, WarningIcon } from "@chakra-ui/icons";
import { PhoneIcon } from "@chakra-ui/icons";
// import { useNavigate } from "react-router-dom";
// import { useSelector } from "react-redux";
import PaymentModal from "../sections/PaymentMethod";
import RebookAppointmentModal from "../sections/RebookAppointmentModal";
import { baseUrl } from "../../apiCalls/config";

export const AppointmentList = ({ appointments, handleViewMore }) => {
  return (
    <Box>
      <Flex
        mt={{ base: "-10px", md: "-10px" }}
        mb="50px"
        w={{ base: "90vw", md: "60vw" }}
        position="relative"
        ml={{ base: "-8px", md: "-20px" }}
        bg="#D087E2"
        p={{ base: "4", md: "26px" }}
        // borderRadius="lg"
        justifyContent="space-between"
        color="white"
        fontSize={{ base: "10px", md: "16px" }}
      >
        <Text fontWeight="bold">Name</Text>
        <Text fontWeight="bold">Type</Text>
        <Text fontWeight="bold">Plan</Text>
        <Text fontWeight="bold">Status</Text>
        <Text fontWeight="bold">Payment</Text>
      </Flex>
      <VStack
        css={{
          "&::-webkit-scrollbar": { display: "none" },
          "-ms-overflow-style": "none" /* IE and Edge */,
          "scrollbar-width": "none" /* Firefox */,
        }}
        mb={{ base: "150", md: "250" }}
        overflow="scroll"
        justifyContent="space-between"
        mt={{ base: "-50px", md: "-40px" }}
        align="start"
        spacing={4}
      >
        {appointments.map((appointment) => (
          <Box
            ml={{ base: "10px" }}
            onClick={() => handleViewMore(appointment)}
            key={appointment.id}
            style={{ cursor: "pointer" }}
            w={{ base: "85vw", md: "57vw" }}
            p={4}
            borderBottom="1px solid #e2e8f0"
          >
            <Flex
              fontSize={{ base: "10px", md: "14px" }}
              textAlign="left"
              ml={{ base: "-15px", md: "-16px" }}
              justifyContent="space-between"
            >
              <Text
                textAlign="left"
                maxW={{ base: "80px", md: "100px" }}
                wordWrap="break-word"
              >
                {`${appointment.recipientFirstname} ${appointment.recipientLastname}`}
              </Text>
              <Text
                textAlign="left"
                maxW={{ base: "50px", md: "100px" }}
              >{`${appointment.shift} `}</Text>
              <Text
                textAlign="left"
                maxW={{ base: "60px", md: "100px" }}
                wordWrap="break-word"
              >{`${appointment.servicePlan} `}</Text>
              <Box
                w={{ base: "60px", md: "97px" }}
                h={{ base: "25px", md: "33px" }}
                textAlign="center"
                borderRadius="10px"
                p="5px"
                bg={
                  appointment.appointmentCompleted
                    ? "#D087E2"
                    : appointment.appointmentActive
                    ? "#ACE1C1"
                    : appointment.appointmentMatched
                    ? "#F4DDA2"
                    : appointment.appointmentPending
                    ? "#F4DDA2"
                    : "black"
                }
              >
                <Text
                  textAlign="center"
                  fontSize={{ base: "10px", md: "14px" }}
                  color={
                    appointment.appointmentCompleted
                      ? "#A210C6"
                      : appointment.appointmentActive
                      ? "#057B1F"
                      : appointment.appointmentMatched
                      ? "#B48B25"
                      : appointment.appointmentPending
                      ? "#B48B25"
                      : "black"
                  }
                >
                  {appointment.appointmentCompleted
                    ? "Completed"
                    : appointment.appointmentActive
                    ? "Active"
                    : appointment.appointmentMatched
                    ? "Paired"
                    : appointment.appointmentPending
                    ? "Pending"
                    : "Unknown"}
                </Text>
              </Box>
              <Box
                w={{ base: "50px", md: "97px" }}
                h={{ base: "25px", md: "33px" }}
                borderRadius="10px"
                p="5px"
              >
                <Text
                  fontSize={{ base: "10px", md: "14px" }}
                  fontWeight="bold"
                  textAlign="center"
                  color={appointment?.paid ? "#057B1F" : "red.500"}
                >
                  {appointment?.paid ? "Paid" : "Unpaid"}
                </Text>
              </Box>
            </Flex>
          </Box>
        ))}
      </VStack>
    </Box>
  );
};
export const AppointmentDetails = ({
  isOpen,
  onClose,
  appointment,
  handlePayment,
  handleEditAppointment,
  handleCancelAppointment,
  handleRebookAppointment,
  handleViewMedicDetails,
}) => {
  // const navigate = useNavigate();
  // const { user } = useSelector((state) => state.userReducer);
  const [isRematchModalOpen, setRematchModalOpen] = React.useState(false);
  const [paymentData, setPaymentData] = useState({});
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [isRebookModalOpen, setRebookModalOpen] = React.useState(false);
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const date = new Date(dateString);
    date.setHours(date.getHours() + 1);
    return date.toLocaleDateString(undefined, options);
  };

  const formatDateTime = (dateTimeString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    return new Date(dateTimeString).toLocaleDateString(undefined, options);
  };

  const handleRebook = ({ startDate, endDate }) => {
    handleRebookAppointment({ ...appointment, startDate, endDate });
  };

  // const handleSubscribeClick = (appointment) => {
  //   const paymentDetails = {
  //     costOfService: appointment.costOfService,
  //     appointmentId: appointment.id,
  //     startDate: appointment.startDate,
  //     endDate: appointment.endDate,
  //     beneficiary: `${appointment.recipientFirstname} ${appointment.recipientLastname}`,
  //     actualStartDate: appointment.actualStartDate,
  //     actualEndDate: appointment.actualEndDate,
  //   };

  //   setTimeout(() => {
  //     console.log("Appointment is on transit: ", paymentDetails);
  //     navigate("/app-sub-confirmation", {
  //       state: { ...paymentDetails },
  //     });
  //   }, 2000);
  // };

  const formattedCost = (amount) => {
    const num = Number(amount);
    return "₦ " + num.toLocaleString();
  };

  // const isWithin30Or31Days = (start, end) => {
  //   const startDate = new Date(start);
  //   const endDate = new Date(end);
  //   const diffTime = Math.abs(endDate - startDate);
  //   const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  //   console.log("diffDays: ", diffDays);
  //   return diffDays === 30 || diffDays === 31;
  // };

  const handlePaymentRenewal = (selectedAppointment) => {
    setPaymentData({
      costOfService: selectedAppointment.costOfService,
      appointmentId: selectedAppointment.id,
      endDate: selectedAppointment.endDate,
      startDate: selectedAppointment.startDate,
      beneficiary: `${selectedAppointment.recipientFirstname} ${selectedAppointment.recipientLastname}`,
    });
    setTimeout(() => {
      setIsPaymentModalOpen(true);
    }, 1000);
  };

  return (
    <>
      <Drawer
        style={{ zIndex: 1400 }}
        isOpen={isOpen}
        onClose={onClose}
        placement="right"
        size="md"
        blockScrollOnMount={false}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottomWidth="1px"
            fontSize="lg"
            fontWeight="bold"
            color="#A210C6"
          >
            Appointment Details
            <Button
              variant="ghost"
              onClick={onClose}
              leftIcon={<CloseIcon />}
            />
          </DrawerHeader>
          {appointment.appointmentActive && (
            <Button
              ml={{ base: "5px" }}
              bg="blue.400"
              color="white"
              _hover={{ color: "" }}
              mb="10px"
              onClick={handleViewMedicDetails}
            >
              View Medic Details
            </Button>
          )}
           {appointment.appointmentActive && (
            <Button
            ml={{ base: "5px" }}
            bg="green.400"
            color="white"
            mb="10px"
            _hover={{ color: "" }}
            onClick={() => handlePaymentRenewal(appointment)}
            leftIcon={<CheckIcon />}
          >
            Renew appointment
          </Button>
          )}
          {appointment.appointmentActive && (
            <Button
              ml={{ base: "5px" }}
              bg="yellow.400"
              color="white"
              _hover={{ color: "" }}
              mb="10px"
              onClick={() => setRematchModalOpen(true)}
            >
              Request for a Re-match
            </Button>
          )}
          {!appointment.paid && (
            <Button
              ml={{ base: "5px" }}
              bg="green.400"
              color="white"
              _hover={{ color: "" }}
              onClick={() => handlePayment(appointment)}
              leftIcon={<CheckIcon />}
            >
              Pay for appointment
            </Button>
          )}
          {appointment.appointmentCompleted && (
            <Button
              ml="10px"
              bg="#A210C6"
              w="full"
              color="white"
              _hover={{ color: "" }}
              onClick={() => setRebookModalOpen(true)}
            >
              Rebook appointment
            </Button>
          )}
          <DrawerBody overflowY="auto">
            <Flex
              flexDirection="column"
              alignItems="start"
              justifyContent="flex-start"
              marginLeft="20px"
            >
              <Flex>
                <Text fontWeight="bold">Status:</Text>
                <Text
                  fontSize="16px"
                  marginLeft="20px"
                  color={
                    appointment.appointmentCompleted
                      ? "green.500"
                      : appointment.appointmentActive
                      ? "blue.500"
                      : appointment.appointmentMatched
                      ? "yellow.500"
                      : appointment.appointmentPending
                      ? "yellow.500"
                      : "black"
                  }
                >
                  {appointment.appointmentCompleted
                    ? "Completed"
                    : appointment.appointmentActive
                    ? "Active"
                    : appointment.appointmentMatched
                    ? "Paired"
                    : appointment.appointmentPending
                    ? "Pending"
                    : "Unknown"}
                </Text>
              </Flex>
              <Divider my={4} borderColor="gray.500" />
              <Flex>
                <Text fontWeight="bold" color="black">
                  Beneficiary name:
                </Text>
                <Text marginLeft="20px" color="black">
                  {appointment.recipientFirstname &&
                  appointment.recipientLastname
                    ? `${appointment.recipientFirstname} ${appointment.recipientLastname}`
                    : "Not available"}
                </Text>
              </Flex>
              <Divider my={4} borderColor="gray.500" />
              <Flex marginTop="5px">
                <Text fontWeight="bold" color="black">
                  MH Policy No:
                </Text>
                <Text marginLeft="20px" color="black">
                  {appointment?.policyNumber || "Not available"}
                </Text>
              </Flex>
              <Divider my={4} borderColor="gray.500" />
              <Flex marginTop="5px">
                <Text fontWeight="bold" color="black">
                  Phone Number:
                </Text>
                <Text marginLeft="20px" color="black">
                  {appointment.recipientPhoneNumber || "Not available"}
                </Text>
              </Flex>
              <Divider my={4} borderColor="gray.500" />
              <Flex marginTop="5px">
                <Text fontWeight="bold" color="black">
                  Gender:
                </Text>
                <Text marginLeft="20px" color="black">
                  {appointment.recipientGender || "Not available"}
                </Text>
              </Flex>
              <Divider my={4} borderColor="gray.500" />
              <Flex marginTop="5px">
                <Text fontWeight="bold" color="black">
                  Date of Birth:
                </Text>
                <Text marginLeft="20px" color="black">
                  {formatDate(appointment.recipientDOB) || "Not available"}
                </Text>
              </Flex>
              <Divider my={4} borderColor="gray.500" />
              <Flex marginTop="5px">
                <Text fontWeight="bold" color="black">
                  Current Location:
                </Text>
                <Text marginLeft="20px" color="black">
                  {appointment.currentLocation || "Not available"}
                </Text>
              </Flex>
              <Divider my={4} borderColor="gray.500" />
              <Flex marginTop="5px">
                <Text fontWeight="bold" color="black">
                  City/Town:
                </Text>
                <Text marginLeft="20px" color="black">
                  {appointment.recipientTown || "Not available"}
                </Text>
              </Flex>
              <Divider my={4} borderColor="gray.500" />
              <Flex marginTop="5px">
                <Text fontWeight="bold" color="black">
                 State:
                </Text>
                <Text marginLeft="20px" color="black">
                  {appointment.state || "Not available"}
                </Text>
              </Flex>
              <Divider my={4} borderColor="gray.500" />
              <Flex marginTop="5px">
                <Text fontWeight="bold" color="black">
                  Preferred Caregiver Gender:
                </Text>
                <Text marginLeft="20px" color="black">
                  {appointment.preferredMedicGender || "Not available"}
                </Text>
              </Flex>
              <Divider my={4} borderColor="gray.500" />
              <Flex marginTop="5px">
                <Text fontWeight="bold" color="black">
                  Preferred Cargiver Language:
                </Text>
                <Text marginLeft="20px" color="black">
                  {appointment.preferredLanguage || "Not available"}
                </Text>
              </Flex>
              <Divider my={4} borderColor="gray.500" />
              <Flex marginTop="5px">
                <Text fontWeight="bold" color="black">
                  Preferred Religion:
                </Text>
                <Text marginLeft="20px" color="black">
                  {appointment.preferredReligion|| "Not available"}
                </Text>
              </Flex>
              <Divider my={4} borderColor="gray.500" />
              <Flex marginTop="5px">
                <Text fontWeight="bold" color="black">
                  Relationship:
                </Text>
                <Text marginLeft="20px" color="black">
                  {appointment.relationship || "Nil"}
                </Text>
              </Flex>
              <Divider my={4} borderColor="gray.500" />
              <Flex marginTop="5px" marginBottom="10px">
                <Text fontWeight="bold" color="black">
                  Booked on:
                </Text>
                <Text marginLeft="20px" color="black">
                  {formatDateTime(appointment.createdAt)}
                </Text>
              </Flex>
              <Divider my={4} borderColor="gray.500" />

              <Flex>
                <Text fontWeight="bold" color="black">
                  Shift:
                </Text>
                <Text marginLeft="20px" color="black">
                  {appointment.shift || "Not available"}
                </Text>
              </Flex>
              <Divider my={4} borderColor="gray.500" />

              <Flex marginTop="5px">
                <Text fontWeight="bold" color="black">
                  Service Plan
                </Text>
                <Text marginLeft="20px" color="black">
                  {appointment.servicePlan || "Not available"}
                </Text>
              </Flex>
              <Divider my={4} borderColor="gray.500" />
              <Flex marginTop="5px">
                <Text fontWeight="bold" color="black">
                  Type of caregiver
                </Text>
                <Text marginLeft="20px" color="black">
                  {appointment.medicSpecialization || "Not available"}
                </Text>
              </Flex>
              <Divider my={4} borderColor="gray.500" />
              <Flex marginTop="5px">
                <Text fontWeight="bold" color="black">
                  Cost of service
                </Text>
                <Text marginLeft="20px" color="black">
                  {formattedCost(appointment.costOfService) || "Not available"}
                </Text>
              </Flex>
              <Divider my={4} borderColor="gray.500" />
              <Flex marginTop="5px">
                <Text fontWeight="bold" color="black">
                  Start Date:
                </Text>
                <Text marginLeft="20px" color="black">
                  {appointment.startDate || "Not available"}
                </Text>
              </Flex>

              <Divider my={4} borderColor="gray.500" />
              <Flex marginTop="5px">
                <Text fontWeight="bold" color="black">
                  Duration:
                </Text>
                <Text marginLeft="20px" color="black">
                  {appointment.duration || "Not available"} day(s)
                </Text>
              </Flex>

              <Divider my={4} borderColor="gray.500" />
              <Flex marginTop="5px">
                <Text fontWeight="bold" color="black">
                  Paid:
                </Text>
                <Text marginLeft="20px" color="black">
                  {appointment.paid ? "Yes" : "No"}
                </Text>
              </Flex>
              <Divider my={4} borderColor="gray.500" />
            </Flex>
            <Box>
              <Flex marginTop="5px">
                <Text marginLeft="20px" fontWeight="bold" color="black">
                  Health History:
                </Text>
                <Text
                  marginLeft="10px"
                  color="black"
                  maxW="600px"
                  maxH="1000px"
                >
                  {appointment.recipientHealthHistory || "Not available"}
                </Text>
              </Flex>
              <Divider my={4} borderColor="gray.500" />
            </Box>
            <Box>
              <Flex marginTop="5px">
                <Text marginLeft="20px" fontWeight="bold" color="black">
                  Special Needs:
                </Text>
                <Text
                  marginLeft="10px"
                  color="black"
                  maxW="600px"
                  maxH="1000px"
                >
                  {appointment.specialNeeds &&
                  appointment.specialNeeds.length > 0
                    ? appointment.specialNeeds.join(", ")
                    : "Not available"}
                </Text>
              </Flex>
              <Divider my={4} borderColor="gray.500" />
            </Box>
          </DrawerBody>
          <DrawerFooter justifyContent="space-between">
            {appointment && (
              <Button
                bg="gray.500"
                color="white"
                _hover={{ color: "" }}
                onClick={onClose}
              >
                Close
              </Button>
            )}
            {/* {appointment.appointmentActive &&
              user?.walletCreated &&
              !appointment.subscription &&
              isWithin30Or31Days(
                appointment.actualStartDate,
                appointment.actualEndDate
              ) && (
                <Button
                  bg="#A210C6"
                  color="white"
                  _hover={{ color: "" }}
                  onClick={() => handleSubscribeClick(appointment)}
                >
                  Subscribe
                </Button>
              )} */}
            {appointment.appointmentPending && (
              <Box>
                <Button
                  bg="#A210C6"
                  color="white"
                  _hover={{ color: "" }}
                  leftIcon={<EditIcon />}
                  onClick={handleEditAppointment}
                >
                  Edit
                </Button>
                {!appointment.paid && (
                  <Button
                    ml="10px"
                    bg="#E1ACAE"
                    color="red.500"
                    _hover={{ color: "" }}
                    onClick={() => handleCancelAppointment(appointment.id)}
                  >
                    Delete
                  </Button>
                )}
              </Box>
            )}
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      <RebookAppointmentModal
        isOpen={isRebookModalOpen}
        onClose={() => setRebookModalOpen(false)}
        onRebook={handleRebook}
        appointment={appointment}
      />
        <PaymentModal
        isOpen={isPaymentModalOpen}
        onClose={() => setIsPaymentModalOpen(false)}
        paymentData={paymentData}
      />
      <RematchModal
        isOpen={isRematchModalOpen}
        onClose={() => setRematchModalOpen(false)}
        appointmentId={appointment.id}
      />
    </>
  );
};

export const CancelAppointmentModal = ({
  isOpen,
  onClose,
  handleConfirmation,
}) => {
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const modalWidth = isLargerThan768 ? "400px" : "90vw";
  const [reason, setReason] = useState("");
  const [isReasonRequired, setIsReasonRequired] = useState(false);

  const handleYesClick = () => {
    if (!reason.trim()) {
      setIsReasonRequired(true);
    } else {
      handleConfirmation(reason);
      onClose();
    }
  };

  const handleInputChange = (e) => {
    setReason(e.target.value);
    if (e.target.value.trim()) {
      setIsReasonRequired(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md">
      <ModalOverlay />
      <ModalContent width={modalWidth} borderRadius="25px 25px 25px 0px">
        <ModalHeader>
          <WarningIcon w={10} h={10} color="yellow.400" />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          This action is irreversible. <br></br>Are you sure you want to cancel
          this appointment? <br />
          <Textarea
            mt={4}
            placeholder="please provide a reason for cancellation"
            value={reason}
            onChange={handleInputChange}
            isInvalid={isReasonRequired}
          />
        </ModalBody>
        <ModalFooter>
          <Button bg="#A210C6" color="white" onClick={onClose}>
            No
          </Button>
          <Button
            bg="#E1ACAE"
            color="red.500"
            marginLeft="5px"
            onClick={handleYesClick}
          >
            Yes
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export const MedicDetailsDrawer = ({ isOpen, onClose, medic }) => {
  if (!medic) return null;

  return (
    <Drawer
      style={{ zIndex: 1400 }}
      isOpen={isOpen}
      onClose={onClose}
      placement="right"
      size="md"
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          borderBottomWidth="1px"
          fontSize="lg"
          fontWeight="bold"
          color="#A210C6"
        >
          Caregiver Details
          <Button variant="ghost" onClick={onClose} leftIcon={<CloseIcon />} />
        </DrawerHeader>
        <DrawerBody justifyContent="center" overflowY="auto">
          <Avatar
            src={medic.bioData?.image}
            color="white"
            alt="User Image"
            borderRadius="8px"
            ml={{ base: "85px", md: "10px" }}
            h={{ base: "260px", md: "55vh" }}
            w={{ base: "200px", md: "full" }}
            marginTop="40px"
            bg="#A210C6"
          ></Avatar>
          <Button
            mt="10px"
            w="full"
            colorScheme="green"
            as="a"
            href={`tel:${medic.bioData.phoneNumber}`}
            leftIcon={<PhoneIcon />}
          >
            Call Caregiver
          </Button>
          <Flex flexDirection="column" alignItems="start" marginLeft="20px">
            <Flex marginTop="5px">
              <Text fontWeight="bold" color="black">
                Name:
              </Text>
              <Text marginLeft="20px" color="black">
                {`${medic.bioData.firstName} ${medic.bioData.lastName}`}
              </Text>
            </Flex>
            <Divider my={4} borderColor="gray.500" />
            <Flex marginTop="5px">
              <Text fontWeight="bold" color="black">
                Phone Number:
              </Text>
              <Text marginLeft="20px" color="black">
                {medic.bioData.phoneNumber}
              </Text>
            </Flex>
            <Divider my={4} borderColor="gray.500" />
            <Flex marginTop="5px">
              <Text fontWeight="bold" color="black">
                Email:
              </Text>
              <Text marginLeft="20px" color="black">
                {medic.bioData.email}
              </Text>
            </Flex>
            <Divider my={4} borderColor="gray.500" />
            <Flex marginTop="5px">
              <Text fontWeight="bold" color="black">
                Gender:
              </Text>
              <Text marginLeft="20px" color="black">
                {medic.bioData.gender}
              </Text>
            </Flex>
            <Divider my={4} borderColor="gray.500" />
            <Flex marginTop="5px">
              <Text fontWeight="bold" color="black">
                Specialization:
              </Text>
              <Text marginLeft="20px" color="black">
                {medic.specialization}
              </Text>
            </Flex>
            <Divider my={4} borderColor="gray.500" />
            <Flex marginTop="5px">
              <Text fontWeight="bold" color="black">
                Years of Experience:
              </Text>
              <Text marginLeft="20px" color="black">
                {medic.yearsOfExp}
              </Text>
            </Flex>
            <Divider my={4} borderColor="gray.500" />
            <Flex marginTop="5px">
              <Text fontWeight="bold" color="black">
                Address:
              </Text>
              <Text marginLeft="20px" color="black">
                {medic.medicHomeAddress}
              </Text>
            </Flex>
          </Flex>
          <DrawerFooter>
            <Button mr={3} bg="gray.500" color="white" onClick={onClose}>
              Close
            </Button>
          </DrawerFooter>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

const RematchModal = ({ isOpen, onClose, appointmentId, onRematch }) => {
  const [date, setDate] = useState("");
  const [reason, setReason] = useState("");
  const toast = useToast();

  const handleSubmit = async () => {
    if (!date || !reason) {
      toast({
        title: "All fields are required.",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    const params = new URLSearchParams();
    params.append("appointmentId", appointmentId);
    params.append("startDate", date);
    params.append("reason", reason);

    try {
      const token = localStorage.getItem("token");

      const response = await axios({
        method: "post",
        url: `${baseUrl}/appointment/rematch`,
        data: params,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status !== 200) {
        throw new Error("Failed to request rematch");
      }

      toast({
        title: "Rematch requested successfully.",
        status: "success",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
      onClose();
    } catch (error) {
      toast({
        title: "Failed to request rematch.",
        description: error.message,
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  return (
    <Modal
      size={{ base: "sm", sm: "md", md: "lg" }}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="lg" fontWeight="bold" color="#A210C6">
          Request for a Re-match
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text mb={4}>
            We understand that sometimes you might need to request a re-match
            for a caregiver for different reasons. <br></br>
            <br></br>To help us accommodate your request, please provide the
            date when you would like the re-match to take place and a brief
            explanation of the reason for your request.
            <br></br>
            <br></br>
            Your satisfaction is our priority, and we're here to ensure you
            receive the best care possible.
          </Text>
          <Input
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            placeholder="Select Date"
            mb={4}
            isRequired
          />
          <Textarea
            placeholder="Enter the reason for re-match"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            isRequired
          />
        </ModalBody>
        <ModalFooter>
          <Button bg="#A210C6" color="white" mr={3} onClick={handleSubmit}>
            Submit
          </Button>
          <Button bg="gray.400" color="white" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
