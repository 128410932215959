// const url = "http://localhost:8080/v1";
// export const url = process.env.REACT_APP_MH_BASE_URL;
export const url = "https://backend-c1pz.onrender.com/v1";
// export const url = "https://backend-staging-xfll.onrender.com";
export const baseUrl = url;


// const loginUrl = "http://localhost:8080";
// export const loginUrl = process.env.REACT_APP_MH_LOGIN_BASE_URL;
export const loginUrl = "https://backend-c1pz.onrender.com";
// export const loginUrl = "https://backend-staging-xfll.onrender.com";
export const loginBaseUrl = loginUrl;